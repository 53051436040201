import { Typography } from "@mui/material";
import { Image } from "../../../../utils";
import { KoreaIcon } from "../../../../components";
import { Common } from "./Common";
import { ComponentProps } from "./Props";

export function Korea({brightness, url}: ComponentProps) {
  return <Common
    brightness={brightness}
    url={url}
    title1="Синтетическое базовое масло из Кореи"
    title2="Формулирование с использованием базового масла группы III, произведенного в Корее"
    title3="Корея — крупнейший в мире производитель высококачественного базового масла GⅢ, которое обладает хорошими<br />свойствами, такими как более высокий индекс вязкости, лучшие низкотемпературные свойства (CCS, MRV, температура застывания),<br />лучшие высокотемпературные свойства (HTHS, температура вспышки), низкая летучесть, лучшая окислительная стабильность, лучшая устойчивость к сдвигу"
    Icon={KoreaIcon}
  >
    <Image path='photos/' name='korea-component1' />
    <Typography textAlign='center' color='white'>
      Моторное масло состоит на 80-90% из базового масла и на 10-20% из добавок.<br />
      K1 Lube — это моторное масло, произведенное с использованием базового масла группы III, закупаемого отечественными и зарубежными автопроизводителями<br />
      и производителями тяжелой техники, с множеством формул, утвержденных по стандартам API и рекомендованных европейскими автопроизводителями.
    </Typography>
  </Common>
}