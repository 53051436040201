export function PAOIcon({color = '#D6B776'}: {color?: string}) {
  return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 132 148" height="148" width="132">
    <g>
      <g clipPath="url(#7abf7e10-1737-4bec-98d4-8bb2d8660241_comp-lqii61o7__item-lqfa3bxk)">
        <path strokeWidth="4.02" stroke={color} d="M129.95 125.94c0 11.05-8.96 20.01-20.01 20.01H22.02c-11.06 0-20.01-8.96-20.01-20.01V38.01c0-11.05 8.95-20.02 20.01-20.02h87.92c11.05 0 20.01 8.96 20.01 20.02v87.93Z" fill="none"></path>
        <path fill={color} d="M118.61 128.86H15.63v2.91h102.98v-2.91Z"></path>
        <path fill={color} d="M17.37 53.53h15.82c2.34 0 4.41.32 6.23.96 1.82.64 3.37 1.56 4.64 2.74 1.28 1.18 2.25 2.59 2.94 4.23.69 1.64 1.03 3.45 1.03 5.45v.11c0 2.25-.4 4.23-1.21 5.92-.82 1.7-1.93 3.12-3.35 4.26-1.42 1.15-3.08 2-4.98 2.57-1.9.57-3.94.86-6.11.86h-6.47v11.62h-8.53V53.53h-.01Zm15.27 19.53c2.14 0 3.8-.57 4.98-1.69 1.19-1.12 1.77-2.52 1.77-4.18v-.11c0-1.92-.61-3.37-1.86-4.37-1.23-.99-2.91-1.5-5.05-1.5H25.9v11.85h6.75-.01Z"></path>
        <path fill={color} d="M62.35 18h6.78l14.32 74.26h-7.69L72.7 75.72H58.57l-3.06 16.54h-7.5L62.35 18Zm9 50.02-5.7-30.54-5.71 30.54h11.41Z"></path>
        <path fill={color} d="M103.19 92.92c-2.6 0-4.99-.53-7.17-1.6a17.99 17.99 0 0 1-5.67-4.34c-1.59-1.82-2.83-3.96-3.71-6.42-.88-2.46-1.33-5.1-1.33-7.92v-.12c0-2.82.45-5.45 1.35-7.9.89-2.46 2.14-4.62 3.73-6.47 1.59-1.87 3.48-3.33 5.69-4.4 2.2-1.07 4.61-1.6 7.2-1.6s5 .53 7.18 1.6c2.18 1.07 4.07 2.51 5.67 4.34 1.58 1.82 2.82 3.96 3.71 6.41.87 2.46 1.32 5.1 1.32 7.92v.1c0 2.82-.45 5.46-1.35 7.92-.89 2.46-2.14 4.62-3.73 6.47a17.564 17.564 0 0 1-5.68 4.4c-2.2 1.07-4.62 1.6-7.22 1.6l.01.01Zm.1-8c1.47 0 2.85-.32 4.09-.96a9.435 9.435 0 0 0 3.21-2.65c.88-1.13 1.57-2.43 2.07-3.92.49-1.48.74-3.07.74-4.76v-.12c0-1.69-.25-3.28-.74-4.78-.5-1.5-1.21-2.81-2.13-3.94-.91-1.12-2-2.02-3.24-2.67a8.833 8.833 0 0 0-4.1-.99c-1.47 0-2.88.33-4.12.96a9.99 9.99 0 0 0-3.19 2.65c-.88 1.12-1.56 2.43-2.06 3.91s-.75 3.06-.75 4.76v.1c0 1.7.25 3.29.75 4.79s1.2 2.81 2.12 3.94c.92 1.12 1.99 2.02 3.23 2.67 1.23.65 2.61.98 4.12.98v.03Z"></path>
        <path fill={color} d="M17.29 104.84h5.56c1.1 0 1.92.05 2.46.14.54.09 1.02.28 1.45.57.43.29.78.68 1.07 1.16.29.48.43 1.03.43 1.63 0 .65-.18 1.25-.53 1.79-.35.54-.83.95-1.43 1.22.85.25 1.5.67 1.95 1.26.46.59.68 1.29.68 2.1 0 .63-.15 1.25-.44 1.84-.29.6-.7 1.08-1.21 1.43-.51.35-1.14.58-1.88.66-.47.05-1.6.08-3.39.09h-4.73v-13.91l.01.02Zm2.81 2.32v3.22h1.84c1.09 0 1.77-.02 2.04-.05.48-.06.86-.22 1.13-.5.27-.28.41-.64.41-1.09 0-.45-.12-.78-.36-1.05s-.59-.43-1.06-.49c-.28-.03-1.08-.05-2.4-.05h-1.61l.01.01Zm0 5.53v3.72h2.6c1.01 0 1.65-.03 1.93-.08.42-.08.76-.26 1.02-.56.26-.3.39-.69.39-1.18 0-.42-.1-.77-.3-1.06-.2-.29-.5-.5-.88-.64s-1.21-.2-2.49-.2H20.1Z"></path>
        <path fill={color} d="M30.74 111.88c0-1.42.21-2.61.64-3.57.32-.71.75-1.34 1.3-1.91.55-.56 1.15-.98 1.8-1.25.87-.37 1.87-.55 3-.55 2.05 0 3.69.64 4.92 1.91s1.85 3.04 1.85 5.3-.61 4-1.83 5.27c-1.22 1.27-2.85 1.9-4.9 1.9-2.05 0-3.71-.63-4.93-1.89-1.22-1.26-1.83-3-1.83-5.21h-.02Zm2.89-.09c0 1.58.36 2.77 1.09 3.58.73.81 1.65 1.22 2.77 1.22s2.04-.4 2.76-1.21c.72-.81 1.08-2.02 1.08-3.63 0-1.61-.35-2.78-1.05-3.57-.7-.78-1.63-1.18-2.79-1.18-1.16 0-2.09.4-2.8 1.19-.71.79-1.06 1.99-1.06 3.59v.01Z"></path>
        <path fill={color} d="M45.86 111.88c0-1.42.21-2.61.64-3.57.32-.71.75-1.34 1.3-1.91.55-.56 1.15-.98 1.8-1.25.87-.37 1.87-.55 3-.55 2.05 0 3.69.64 4.92 1.91s1.85 3.04 1.85 5.3-.61 4-1.83 5.27c-1.22 1.27-2.85 1.9-4.9 1.9-2.05 0-3.71-.63-4.93-1.89-1.22-1.26-1.83-3-1.83-5.21h-.02Zm2.89-.09c0 1.58.36 2.77 1.09 3.58.73.81 1.65 1.22 2.77 1.22s2.04-.4 2.76-1.21c.72-.81 1.08-2.02 1.08-3.63 0-1.61-.35-2.78-1.05-3.57-.7-.78-1.63-1.18-2.79-1.18-1.16 0-2.09.4-2.8 1.19-.71.79-1.06 1.99-1.06 3.59v.01Z"></path>
        <path fill={color} d="m60.83 114.22 2.73-.27c.16.92.5 1.59 1 2.02.5.43 1.18.65 2.04.65.9 0 1.59-.19 2.04-.57.46-.38.69-.83.69-1.34 0-.33-.1-.61-.29-.84-.19-.23-.53-.43-1.01-.6-.33-.11-1.08-.32-2.25-.61-1.51-.37-2.56-.83-3.17-1.38-.85-.76-1.28-1.7-1.28-2.8 0-.71.2-1.37.6-1.99.4-.62.98-1.09 1.74-1.41.76-.32 1.67-.48 2.74-.48 1.75 0 3.06.38 3.94 1.15.88.77 1.35 1.79 1.39 3.06l-2.81.12c-.12-.71-.38-1.23-.77-1.54-.4-.31-.99-.47-1.78-.47s-1.46.17-1.92.5a1 1 0 0 0-.45.86c0 .33.14.61.42.84.35.3 1.21.61 2.58.93 1.37.32 2.38.66 3.03 1 .65.34 1.17.82 1.54 1.41.37.6.55 1.34.55 2.22 0 .8-.22 1.54-.66 2.24-.44.7-1.07 1.21-1.88 1.55-.81.34-1.82.51-3.03.51-1.76 0-3.11-.41-4.05-1.22s-1.51-2-1.69-3.55l.01.01Z"></path>
        <path fill={color} d="M77.63 118.75v-11.56H73.5v-2.35h11.05v2.35h-4.12v11.56h-2.81.01Z"></path>
        <path fill={color} d="M86.29 118.75v-13.91h2.81v13.91h-2.81Z"></path>
        <path fill={color} d="M91.8 118.75v-13.91h2.73l5.69 9.29v-9.29h2.61v13.91h-2.82l-5.61-9.07v9.07h-2.61.01Z"></path>
        <path fill={color} d="M112.28 113.63v-2.34h6.05v5.54c-.59.57-1.44 1.07-2.56 1.5a9.4 9.4 0 0 1-3.39.65c-1.46 0-2.72-.31-3.8-.92-1.08-.61-1.89-1.48-2.44-2.62a8.54 8.54 0 0 1-.82-3.71c0-1.45.3-2.74.91-3.86.61-1.12 1.5-1.99 2.67-2.59.89-.46 2-.69 3.33-.69 1.73 0 3.08.36 4.05 1.09.97.72 1.6 1.72 1.87 3l-2.79.52c-.2-.68-.56-1.22-1.11-1.62-.54-.4-1.22-.59-2.03-.59-1.23 0-2.2.39-2.93 1.17-.72.78-1.09 1.93-1.09 3.46 0 1.65.37 2.89 1.1 3.71.73.83 1.7 1.24 2.88 1.24.59 0 1.18-.12 1.77-.35.59-.23 1.1-.51 1.52-.84v-1.76h-3.22l.03.01Z"></path>
        <path fill={color} d="m62.35 18 3.4-18 3.38 18"></path>
      </g>
      <defs fill="none">
        <clipPath id="7abf7e10-1737-4bec-98d4-8bb2d8660241_comp-lqii61o7__item-lqfa3bxk">
          <path fill="#ffffff" d="M131.96 0v147.95H0V0h131.96z"></path>
        </clipPath>
      </defs>
    </g>
  </svg>
}