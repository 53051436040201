export function KoreaIcon({color = '#231815'}: {color?: string}) {
  return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 131 131" height="131" width="131">
    <g>
      <g clipPath="url(#f0b84c96-27b6-4a00-a7a1-1187a7f244c5_comp-lqii61o7__item1)">
        <path strokeWidth="4.15" stroke={color} d="M21.91 2.07h87.13c10.95 0 19.83 8.89 19.83 19.83v87.14c0 10.95-8.89 19.84-19.84 19.84H21.91c-10.95 0-19.84-8.89-19.84-19.84V21.91c0-10.95 8.89-19.84 19.84-19.84Z" fill="none"></path>
        <path fill={color} d="M15.57 35.74h7.69v15.28l14.19-15.28h9.29L32.5 50.57l14.89 20.14h-9.25L27.3 55.82l-4.05 4.2v10.69h-7.69V35.74h.01Z"></path>
        <path fill={color} d="M64.43 71.31c-2.7 0-5.18-.48-7.45-1.42-2.27-.95-4.22-2.23-5.87-3.85a17.24 17.24 0 0 1-3.85-5.7c-.92-2.18-1.37-4.52-1.37-7.02v-.09c0-2.5.46-4.84 1.4-7.02.93-2.18 2.22-4.09 3.87-5.75 1.64-1.65 3.61-2.95 5.89-3.89 2.28-.95 4.77-1.42 7.47-1.42s5.18.47 7.45 1.42c2.26.94 4.22 2.23 5.87 3.85 1.65 1.62 2.93 3.51 3.85 5.69.91 2.19 1.37 4.52 1.37 7.02v.1c0 2.5-.47 4.83-1.4 7.01-.93 2.19-2.23 4.1-3.87 5.75-1.65 1.64-3.62 2.94-5.9 3.89-2.28.95-4.77 1.42-7.47 1.42l.01.01Zm.1-7.09c1.53 0 2.95-.29 4.25-.86 1.3-.56 2.41-1.35 3.32-2.34.92-1 1.63-2.16 2.14-3.47.52-1.32.78-2.72.78-4.23v-.09c0-1.5-.26-2.92-.78-4.25-.51-1.33-1.25-2.5-2.2-3.5-.95-1-2.07-1.79-3.37-2.37-1.3-.59-2.71-.88-4.24-.88-1.53 0-2.99.28-4.27.85a9.75 9.75 0 0 0-3.3 2.35c-.92 1-1.63 2.16-2.15 3.47-.51 1.31-.77 2.72-.77 4.22v.1c0 1.49.26 2.91.77 4.24.52 1.33 1.25 2.5 2.2 3.5.95 1 2.06 1.8 3.35 2.37 1.28.58 2.71.88 4.27.88v.01Z"></path>
        <path fill={color} d="M87.41 35.74h15.99c4.43 0 7.83 1.18 10.19 3.55 1.99 1.99 2.99 4.66 2.99 7.99v.1c0 2.83-.69 5.14-2.07 6.92-1.38 1.77-3.19 3.09-5.42 3.92l8.54 12.49h-9l-7.49-11.19h-6.05v11.19H87.4V35.74h.01Zm15.49 16.99c1.89 0 3.35-.45 4.36-1.35 1.02-.9 1.53-2.09 1.53-3.6v-.09c0-1.67-.54-2.92-1.6-3.75-1.07-.83-2.54-1.25-4.45-1.25h-7.65v10.04h7.81Z"></path>
        <path fill={color} d="M68.25 24.5c0 2.09-1.69 3.77-3.77 3.77s-3.77-1.68-3.77-3.77 1.69-3.77 3.77-3.77 3.77 1.69 3.77 3.77Z"></path>
        <path fill={color} d="M68.25 34.05c0 2.09-1.69 3.78-3.77 3.78s-3.77-1.69-3.77-3.78 1.69-3.77 3.77-3.77 3.77 1.69 3.77 3.77Z"></path>
        <path fill={color} d="M15.27 98.29V88.05h2.07v4.55l4.18-4.55h2.78l-3.86 3.99 4.07 6.25h-2.68l-2.82-4.81-1.68 1.71v3.1h-2.07.01Z"></path>
        <path fill={color} d="M24.75 94.47c0-.65.16-1.28.48-1.89.32-.61.78-1.08 1.37-1.4.59-.32 1.25-.48 1.97-.48 1.12 0 2.04.36 2.76 1.09.72.73 1.08 1.65 1.08 2.76 0 1.11-.36 2.05-1.09 2.79-.72.74-1.64 1.11-2.74 1.11a4.3 4.3 0 0 1-1.95-.46c-.62-.31-1.09-.76-1.41-1.35-.32-.59-.48-1.32-.48-2.17h.01Zm2.01.1c0 .74.17 1.3.52 1.69.35.39.78.59 1.29.59s.94-.2 1.29-.59c.35-.39.52-.96.52-1.71s-.17-1.29-.52-1.68c-.35-.39-.78-.59-1.29-.59s-.94.2-1.29.59c-.35.39-.52.96-.52 1.69v.01Z"></path>
        <path fill={color} d="M35.47 98.29h-1.96v-7.42h1.82v1.06c.31-.5.59-.83.84-.98.25-.16.53-.24.85-.24.45 0 .88.12 1.29.37l-.61 1.71c-.33-.21-.64-.32-.92-.32s-.51.08-.7.23c-.19.15-.34.43-.45.82-.11.39-.16 1.23-.16 2.49v2.29-.01Z"></path>
        <path fill={color} d="m43.1 95.93 1.96.33c-.25.72-.65 1.26-1.19 1.64-.54.38-1.22.56-2.04.56-1.29 0-2.25-.42-2.87-1.26-.49-.68-.73-1.53-.73-2.56 0-1.23.32-2.19.96-2.89.64-.7 1.46-1.04 2.44-1.04 1.1 0 1.98.36 2.61 1.09.64.73.94 1.85.92 3.35h-4.92c.01.58.17 1.04.48 1.36.3.32.68.49 1.13.49.31 0 .57-.08.78-.25.21-.17.37-.44.48-.81l-.01-.01Zm.11-1.98c-.01-.57-.16-1-.44-1.3-.28-.3-.62-.44-1.02-.44-.43 0-.78.16-1.06.47-.28.31-.42.74-.41 1.27h2.93Z"></path>
        <path fill={color} d="m47.87 93.13-1.78-.32c.2-.72.54-1.25 1.03-1.59.49-.34 1.22-.52 2.18-.52.88 0 1.53.1 1.96.31.43.21.73.47.91.79.17.32.26.9.26 1.76l-.02 2.29c0 .65.03 1.13.09 1.44.06.31.18.64.35 1h-1.94c-.05-.13-.11-.32-.19-.58-.03-.12-.06-.19-.07-.23-.34.33-.69.57-1.08.73-.38.16-.79.24-1.22.24-.76 0-1.37-.21-1.81-.62-.44-.41-.66-.94-.66-1.57 0-.42.1-.79.3-1.12.2-.33.48-.58.84-.75.36-.17.88-.33 1.56-.46.92-.17 1.55-.33 1.91-.48v-.2c0-.38-.09-.65-.28-.81-.19-.16-.54-.24-1.06-.24-.35 0-.62.07-.82.21-.2.14-.35.38-.47.72h.01Zm2.63 1.59c-.25.08-.65.18-1.2.3-.54.12-.9.23-1.07.34-.26.18-.38.41-.38.69 0 .28.1.51.31.71.21.2.47.3.78.3.35 0 .69-.12 1.01-.35.24-.18.39-.39.47-.65.05-.17.08-.49.08-.96v-.39.01Z"></path>
        <path fill={color} d="m57.11 94.96 2.01-.2c.12.68.37 1.17.74 1.49.37.32.87.48 1.5.48.67 0 1.17-.14 1.51-.42.34-.28.51-.61.51-.99a.95.95 0 0 0-.21-.62c-.14-.17-.39-.32-.74-.44-.24-.08-.79-.23-1.66-.45-1.11-.27-1.89-.61-2.33-1.01-.63-.56-.94-1.25-.94-2.06 0-.52.15-1.01.44-1.46.29-.45.72-.8 1.28-1.04s1.23-.36 2.02-.36c1.29 0 2.25.28 2.9.85.65.56.99 1.32 1.02 2.26l-2.07.09c-.09-.53-.28-.9-.57-1.14-.29-.23-.73-.35-1.31-.35-.58 0-1.07.12-1.41.37-.22.16-.33.37-.33.64 0 .24.1.45.31.62.26.22.89.45 1.9.68 1.01.24 1.75.48 2.23.74s.86.6 1.13 1.04c.27.44.41.98.41 1.63 0 .59-.16 1.14-.49 1.65-.33.51-.79.89-1.38 1.14-.6.25-1.34.37-2.23.37-1.3 0-2.29-.3-2.98-.9-.69-.6-1.11-1.47-1.24-2.62l-.02.01Z"></path>
        <path fill={color} d="M65.88 90.87h2.09l1.77 5.27 1.73-5.27h2.03l-2.62 7.14-.47 1.29c-.17.43-.34.76-.49.99-.16.23-.34.41-.54.56-.2.14-.45.25-.75.33-.3.08-.63.12-1 .12s-.75-.04-1.11-.12l-.17-1.54c.31.06.58.09.83.09.46 0 .79-.13 1.01-.4.22-.27.39-.61.5-1.02l-2.82-7.44h.01Z"></path>
        <path fill={color} d="M81.16 98.29H79.2V94.5c0-.8-.04-1.32-.13-1.55-.09-.23-.22-.42-.41-.55-.19-.13-.42-.2-.68-.2-.34 0-.64.09-.92.28-.27.19-.46.43-.56.74-.1.31-.15.88-.15 1.71v3.36h-1.96v-7.42h1.82v1.09c.65-.84 1.46-1.26 2.45-1.26.43 0 .83.08 1.19.23.36.16.63.36.81.6.18.24.31.52.38.82.07.31.11.75.11 1.32v4.61l.01.01Z"></path>
        <path fill={color} d="M86.2 90.87v1.57h-1.34v2.99c0 .61.01.96.04 1.06.03.1.08.18.17.25.09.06.2.1.33.1.18 0 .44-.06.79-.19l.17 1.52c-.46.2-.97.29-1.55.29-.35 0-.67-.06-.96-.18-.28-.12-.49-.27-.62-.46s-.22-.44-.28-.77c-.04-.23-.06-.69-.06-1.38v-3.24h-.9v-1.57h.9v-1.47l1.97-1.15v2.62h1.34v.01Z"></path>
        <path fill={color} d="M89.16 88.04v3.77c.63-.74 1.39-1.11 2.27-1.11.45 0 .86.08 1.22.25.36.17.64.38.82.64.18.26.31.55.38.87.07.32.1.81.1 1.47v4.35h-1.96v-3.92c0-.78-.04-1.27-.11-1.48a.97.97 0 0 0-.39-.5c-.18-.12-.43-.19-.71-.19-.33 0-.62.08-.87.24-.26.16-.44.4-.56.72-.12.32-.18.79-.18 1.42v3.72h-1.96V88.05h1.96l-.01-.01Z"></path>
        <path fill={color} d="m99.89 95.93 1.96.33c-.25.72-.65 1.26-1.19 1.64-.54.38-1.22.56-2.04.56-1.29 0-2.25-.42-2.87-1.26-.49-.68-.73-1.53-.73-2.56 0-1.23.32-2.19.96-2.89.64-.7 1.46-1.04 2.44-1.04 1.1 0 1.98.36 2.61 1.09.64.73.94 1.85.92 3.35h-4.92c.01.58.17 1.04.48 1.36.3.32.68.49 1.13.49.31 0 .57-.08.78-.25.21-.17.37-.44.48-.81l-.01-.01Zm.11-1.98c-.01-.57-.16-1-.44-1.3-.28-.3-.62-.44-1.02-.44-.43 0-.78.16-1.06.47-.28.31-.42.74-.41 1.27H100Z"></path>
        <path fill={color} d="M106.59 90.87v1.57h-1.34v2.99c0 .61.01.96.04 1.06.03.1.08.18.17.25.09.06.2.1.33.1.18 0 .44-.06.79-.19l.17 1.52c-.46.2-.97.29-1.55.29-.35 0-.67-.06-.96-.18-.28-.12-.49-.27-.62-.46s-.22-.44-.28-.77c-.04-.23-.06-.69-.06-1.38v-3.24h-.9v-1.57h.9v-1.47l1.97-1.15v2.62h1.34v.01Z"></path>
        <path fill={color} d="M107.6 89.86v-1.82h1.96v1.82h-1.96Zm0 8.43v-7.42h1.96v7.42h-1.96Z"></path>
        <path fill={color} d="m117.69 93.06-1.94.35c-.07-.39-.21-.68-.44-.87-.23-.2-.53-.29-.9-.29-.49 0-.88.17-1.17.51-.29.34-.44.9-.44 1.69 0 .88.15 1.5.44 1.87.29.36.69.54 1.19.54.37 0 .68-.11.92-.32.24-.21.41-.58.5-1.09l1.93.33c-.2.89-.58 1.55-1.15 2.01-.57.45-1.33.68-2.29.68-1.09 0-1.95-.34-2.6-1.03-.64-.69-.97-1.63-.97-2.84s.32-2.18.97-2.86c.65-.68 1.52-1.02 2.63-1.02.9 0 1.62.19 2.16.58.54.39.92.98 1.15 1.78l.01-.02Z"></path>
        <path fill={color} d="M41.65 101.8h4.1c.81 0 1.42.03 1.81.1.4.07.75.21 1.07.42.32.21.58.5.79.86.21.36.31.76.31 1.2 0 .48-.13.92-.39 1.32s-.61.7-1.05.9c.62.18 1.1.49 1.44.93.34.44.5.95.5 1.54 0 .47-.11.92-.32 1.36-.22.44-.51.79-.89 1.06s-.84.43-1.39.49c-.34.04-1.18.06-2.5.07h-3.49v-10.24l.01-.01Zm2.07 1.71v2.37h1.36c.81 0 1.31-.01 1.5-.03.35-.04.63-.16.83-.37.2-.2.3-.47.3-.8 0-.33-.09-.57-.26-.77-.17-.2-.43-.32-.78-.36-.21-.02-.79-.03-1.77-.03h-1.19l.01-.01Zm0 4.07v2.74h1.92c.75 0 1.22-.02 1.42-.06.31-.06.56-.19.75-.41.19-.22.29-.51.29-.87 0-.31-.08-.57-.22-.78-.15-.21-.36-.37-.65-.47-.28-.1-.89-.15-1.83-.15h-1.68Z"></path>
        <path fill={color} d="m53.07 106.89-1.78-.32c.2-.72.54-1.25 1.03-1.59.49-.34 1.22-.52 2.18-.52.88 0 1.53.1 1.96.31.43.21.73.47.91.79.17.32.26.9.26 1.76l-.02 2.29c0 .65.03 1.13.09 1.44.06.31.18.64.35 1h-1.94c-.05-.13-.11-.32-.19-.58-.03-.12-.06-.19-.07-.23-.34.33-.69.57-1.08.73-.38.16-.79.24-1.22.24-.76 0-1.37-.21-1.81-.62-.44-.41-.66-.94-.66-1.57 0-.42.1-.79.3-1.12.2-.33.48-.58.84-.75.36-.17.88-.33 1.56-.46.92-.17 1.55-.33 1.91-.48v-.2c0-.38-.09-.65-.28-.81-.19-.16-.54-.24-1.06-.24-.35 0-.62.07-.82.21-.2.14-.35.38-.47.72h.01Zm2.63 1.59c-.25.08-.65.18-1.2.3-.54.12-.9.23-1.07.34-.26.18-.38.41-.38.69 0 .28.1.51.31.71.21.2.47.3.78.3.35 0 .69-.12 1.01-.35.24-.18.39-.39.47-.65.05-.17.08-.49.08-.96v-.39.01Z"></path>
        <path fill={color} d="m58.51 109.93 1.97-.3c.08.38.25.67.51.87.26.2.61.3 1.08.3.51 0 .89-.09 1.15-.28.17-.13.26-.31.26-.52a.53.53 0 0 0-.14-.37c-.1-.09-.32-.18-.66-.26-1.58-.35-2.59-.67-3.01-.96-.59-.4-.88-.96-.88-1.67 0-.64.25-1.18.76-1.62.51-.44 1.29-.66 2.36-.66 1.07 0 1.77.17 2.26.5.49.33.83.82 1.02 1.47l-1.85.34c-.08-.29-.23-.51-.45-.66-.22-.15-.54-.23-.95-.23-.52 0-.89.07-1.11.22-.15.1-.22.24-.22.4 0 .14.07.26.2.36.18.13.79.31 1.83.55 1.05.24 1.78.53 2.19.87.41.35.62.84.62 1.46 0 .68-.28 1.26-.85 1.75s-1.41.73-2.52.73c-1.01 0-1.81-.2-2.4-.61-.59-.41-.97-.97-1.16-1.67l-.01-.01Z"></path>
        <path fill={color} d="m71.11 109.69 1.96.33c-.25.72-.65 1.26-1.19 1.64-.54.38-1.22.56-2.04.56-1.29 0-2.25-.42-2.87-1.26-.49-.68-.73-1.53-.73-2.56 0-1.23.32-2.19.96-2.89.64-.7 1.46-1.04 2.44-1.04 1.1 0 1.98.36 2.61 1.09.64.73.94 1.85.92 3.35h-4.92c.01.58.17 1.04.48 1.36.3.32.68.49 1.13.49.31 0 .57-.08.78-.25.21-.17.37-.44.48-.81l-.01-.01Zm.11-1.98c-.01-.57-.16-1-.44-1.3-.28-.3-.62-.44-1.02-.44-.43 0-.78.16-1.06.47-.28.31-.42.74-.41 1.27h2.93Z"></path>
        <path fill={color} d="M77.62 106.99c0-1.04.16-1.92.47-2.63.23-.52.55-.99.95-1.4.4-.41.84-.72 1.32-.92.64-.27 1.37-.41 2.21-.41 1.51 0 2.72.47 3.62 1.4.91.94 1.36 2.24 1.36 3.91s-.45 2.95-1.35 3.88c-.9.93-2.1 1.4-3.61 1.4-1.51 0-2.73-.46-3.63-1.39-.9-.93-1.35-2.21-1.35-3.84h.01Zm2.13-.07c0 1.16.27 2.04.8 2.64.54.6 1.22.9 2.04.9.82 0 1.5-.3 2.03-.89.53-.59.79-1.48.79-2.67s-.26-2.05-.77-2.63c-.51-.58-1.2-.87-2.05-.87-.85 0-1.54.29-2.06.88-.52.58-.78 1.47-.78 2.64Z"></path>
        <path fill={color} d="M88.8 103.62v-1.82h1.96v1.82H88.8Zm0 8.43v-7.42h1.96v7.42H88.8Z"></path>
        <path fill={color} d="M92.42 112.05v-10.24h1.96v10.24h-1.96Z"></path>
        <path fill={color} d="M117.64 77.25H15.57v2.89h102.07v-2.89Z"></path>
      </g>
      <defs fill="none">
        <clipPath id="f0b84c96-27b6-4a00-a7a1-1187a7f244c5_comp-lqii61o7__item1">
          <path fill="#ffffff" d="M130.95 0v130.95H0V0h130.95z"></path>
        </clipPath>
      </defs>
    </g>
  </svg>
}