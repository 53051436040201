export function EsterIcon({color = '#C2DA69'}: {color?: string}) {
  return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 133 133" height="133" width="133">
    <g>
      <g clipPath="url(#63b1b877-f624-4b55-9be4-e49c23091775_comp-lqii61o7__item-lqfa3aa2)">
        <path fill={color} d="M12.21 35.51h17.7v6.23H18.83v4.64H29.1v5.96H18.83v5.76h11.4v6.61H12.21v-29.2Z"></path>
        <path fill={color} d="m32.63 55.05 6.28-.54c.14 1.39.41 2.46.83 3.19.68 1.18 1.65 1.77 2.92 1.77.94 0 1.67-.3 2.18-.91.51-.6.77-1.3.77-2.1s-.24-1.43-.73-2.03c-.49-.6-1.61-1.16-3.38-1.69-2.9-.89-4.96-2.07-6.19-3.55-1.24-1.47-1.87-3.35-1.87-5.64 0-1.5.32-2.92.95-4.25a7.279 7.279 0 0 1 2.87-3.15c1.28-.76 3.03-1.15 5.25-1.15 2.73 0 4.81.69 6.24 2.08 1.43 1.39 2.29 3.59 2.56 6.62l-6.22.5c-.17-1.31-.51-2.27-1.04-2.87-.53-.6-1.26-.9-2.19-.9-.77 0-1.35.22-1.73.67-.39.45-.58.99-.58 1.62 0 .46.16.88.48 1.25.31.39 1.05.74 2.22 1.08 2.89.85 4.95 1.71 6.2 2.58 1.25.87 2.16 1.95 2.73 3.24.57 1.29.85 2.73.85 4.32 0 1.87-.38 3.6-1.14 5.18-.76 1.58-1.82 2.78-3.18 3.6-1.36.82-3.07 1.22-5.14 1.22-3.63 0-6.15-.96-7.55-2.87-1.4-1.91-2.19-4.34-2.38-7.29l-.01.02Z"></path>
        <path fill={color} d="M53.84 35.51h20.07v7.21h-6.73v21.99h-6.6V42.72h-6.73v-7.21h-.01Z"></path>
        <path fill={color} d="M76.88 35.51h17.69v6.23H83.49v4.64h10.27v5.96H83.49v5.76h11.4v6.61H76.88v-29.2Z"></path>
        <path fill={color} d="M98.53 64.71v-29.2h11c2.04 0 3.6.24 4.68.72 1.08.48 1.95 1.36 2.61 2.66.66 1.29.99 2.87.99 4.73 0 1.62-.25 3.02-.76 4.19-.51 1.17-1.2 2.13-2.08 2.86-.56.46-1.34.85-2.32 1.16.79.36 1.36.72 1.72 1.08.24.24.59.75 1.06 1.54.46.78.77 1.39.92 1.81l3.2 8.46h-7.46l-3.53-8.92c-.45-1.16-.85-1.91-1.2-2.25-.48-.45-1.02-.68-1.62-.68h-.58v11.85h-6.63v-.01Zm6.63-17.37h2.78c.3 0 .88-.13 1.75-.4.44-.12.79-.42 1.07-.92s.42-1.06.42-1.69c0-.94-.22-1.67-.66-2.17-.44-.5-1.26-.76-2.46-.76h-2.9v5.94Z"></path>
        <path strokeWidth="4.02" stroke={color} d="M130.22 110.18c0 11.07-8.98 20.05-20.05 20.05H22.06c-11.08 0-20.05-8.98-20.05-20.05V22.07c0-11.08 8.97-20.06 20.05-20.06h88.11c11.07 0 20.05 8.98 20.05 20.06v88.11Z" fill="none"></path>
        <path fill={color} d="M119.46 76.09H12.9v2.58h106.56v-2.58Z"></path>
        <path fill={color} d="M13.86 92.03c0-1 .15-1.85.45-2.53.22-.5.53-.95.92-1.35.39-.4.81-.7 1.27-.89.61-.26 1.32-.39 2.13-.39 1.45 0 2.62.45 3.49 1.35.87.9 1.31 2.16 1.31 3.76 0 1.6-.43 2.84-1.3 3.74-.87.9-2.02 1.35-3.47 1.35-1.45 0-2.63-.45-3.5-1.34-.87-.89-1.3-2.13-1.3-3.7Zm2.05-.07c0 1.12.26 1.96.77 2.54.51.58 1.17.86 1.96.86s1.45-.29 1.95-.86c.51-.57.76-1.43.76-2.57s-.25-1.97-.74-2.53c-.5-.56-1.15-.83-1.97-.83-.82 0-1.48.28-1.98.84-.5.56-.75 1.41-.75 2.55Z"></path>
        <path fill={color} d="M26.78 96.9h-1.89v-7.14h1.76v1.02c.3-.48.57-.8.81-.95.24-.15.51-.23.82-.23.43 0 .85.12 1.24.36l-.59 1.65c-.32-.21-.61-.31-.89-.31s-.49.07-.67.22c-.18.15-.33.41-.43.79-.11.38-.16 1.18-.16 2.39v2.21-.01Z"></path>
        <path fill={color} d="m30.15 97.37 2.16.26c.04.25.12.42.25.52.18.13.46.2.85.2.49 0 .86-.07 1.11-.22.17-.1.29-.26.38-.48.06-.16.09-.45.09-.87v-1.04c-.56.77-1.28 1.16-2.14 1.16-.96 0-1.72-.41-2.28-1.22-.44-.64-.66-1.44-.66-2.39 0-1.2.29-2.11.86-2.75.58-.63 1.29-.95 2.15-.95.86 0 1.61.39 2.19 1.16v-1h1.77v6.41c0 .84-.07 1.47-.21 1.89-.14.42-.33.74-.59.98-.25.24-.59.42-1.01.56-.42.13-.95.2-1.59.2-1.21 0-2.07-.21-2.58-.62-.51-.41-.76-.94-.76-1.58v-.23l.01.01Zm1.69-4.19c0 .76.15 1.31.44 1.67.29.35.66.53 1.09.53.46 0 .85-.18 1.17-.54.32-.36.48-.9.48-1.6 0-.7-.15-1.29-.46-1.65a1.46 1.46 0 0 0-1.16-.54c-.47 0-.83.18-1.12.53-.29.35-.44.89-.44 1.61v-.01Z"></path>
        <path fill={color} d="m40.16 91.93-1.72-.31c.19-.69.52-1.2 1-1.53.47-.33 1.17-.5 2.1-.5.84 0 1.47.1 1.88.3.41.2.7.45.87.76.17.31.25.87.25 1.69l-.02 2.21c0 .63.03 1.09.09 1.39.06.3.17.62.34.96h-1.87c-.05-.13-.11-.31-.18-.56-.03-.11-.05-.19-.07-.22-.32.31-.67.55-1.04.71-.37.16-.76.24-1.18.24-.74 0-1.32-.2-1.74-.6a2 2 0 0 1-.64-1.51c0-.4.1-.76.29-1.08.19-.32.46-.56.81-.73.35-.17.85-.31 1.5-.44.88-.17 1.5-.32 1.84-.46v-.19c0-.36-.09-.62-.27-.78-.18-.15-.52-.23-1.02-.23-.34 0-.6.07-.79.2-.19.13-.34.36-.46.7l.03-.02Zm2.53 1.53c-.24.08-.63.18-1.15.29-.52.11-.87.22-1.03.33-.25.17-.37.4-.37.67s.1.49.3.69c.2.19.45.29.75.29.34 0 .67-.11.98-.34.23-.17.38-.38.45-.63.05-.16.07-.47.07-.92v-.38Z"></path>
        <path fill={color} d="M52.91 96.9h-1.89v-3.65c0-.77-.04-1.27-.12-1.5-.08-.23-.21-.4-.39-.53-.18-.12-.4-.19-.66-.19-.33 0-.62.09-.88.27-.26.18-.44.42-.53.71-.1.3-.14.84-.14 1.64v3.24h-1.89v-7.14h1.76v1.05c.62-.81 1.41-1.21 2.35-1.21.42 0 .8.08 1.14.23.35.15.61.34.78.58.17.24.3.5.37.79.07.3.1.72.1 1.27v4.44Z"></path>
        <path fill={color} d="M54.82 88.79v-1.75h1.89v1.75h-1.89Zm0 8.11v-7.14h1.89v7.14h-1.89Z"></path>
        <path fill={color} d="m64.88 91.87-1.86.34c-.06-.37-.21-.65-.43-.84-.22-.19-.51-.28-.86-.28-.47 0-.85.16-1.13.49-.28.33-.42.87-.42 1.63 0 .85.14 1.45.43 1.8.28.35.67.53 1.15.53.36 0 .65-.1.88-.31.23-.2.39-.55.48-1.05l1.86.32c-.19.85-.56 1.5-1.11 1.93-.55.44-1.28.65-2.2.65-1.04 0-1.88-.33-2.5-.99-.62-.66-.93-1.57-.93-2.74 0-1.17.31-2.1.94-2.75.62-.66 1.47-.99 2.53-.99.87 0 1.56.19 2.08.56.51.37.88.95 1.11 1.71l-.02-.01Z"></path>
        <path fill={color} d="M70.17 96.9v-9.86h6.76v1.67h-4.77v2.33h4.12v1.67h-4.12v4.19h-1.99Z"></path>
        <path fill={color} d="M80.37 96.9h-1.89v-7.14h1.76v1.02c.3-.48.57-.8.81-.95.24-.15.51-.23.82-.23.43 0 .85.12 1.24.36l-.59 1.65c-.32-.21-.61-.31-.89-.31s-.49.07-.67.22c-.18.15-.33.41-.43.79-.11.38-.16 1.18-.16 2.39v2.21-.01Z"></path>
        <path fill={color} d="M83.92 88.79v-1.75h1.89v1.75h-1.89Zm0 8.11v-7.14h1.89v7.14h-1.89Z"></path>
        <path fill={color} d="m93.98 91.87-1.86.34c-.06-.37-.21-.65-.43-.84-.22-.19-.51-.28-.86-.28-.47 0-.85.16-1.13.49-.28.33-.42.87-.42 1.63 0 .85.14 1.45.43 1.8.28.35.67.53 1.15.53.36 0 .65-.1.88-.31.23-.2.39-.55.48-1.05l1.86.32c-.19.85-.56 1.5-1.11 1.93-.55.44-1.28.65-2.2.65-1.04 0-1.88-.33-2.5-.99-.62-.66-.93-1.57-.93-2.74 0-1.17.31-2.1.94-2.75.62-.66 1.47-.99 2.53-.99.87 0 1.56.19 2.08.56.51.37.88.95 1.11 1.71l-.02-.01Z"></path>
        <path fill={color} d="M98.69 89.75v1.51H97.4v2.88c0 .58.01.92.04 1.02.02.1.08.18.17.24.09.06.19.09.32.09.17 0 .43-.06.76-.18l.16 1.47c-.44.19-.94.28-1.49.28-.34 0-.65-.06-.92-.17-.27-.11-.47-.26-.6-.44s-.22-.43-.27-.74c-.04-.22-.06-.66-.06-1.33v-3.12h-.87v-1.51h.87v-1.42l1.9-1.1v2.52h1.29-.01Z"></path>
        <path fill={color} d="M100 88.79v-1.75h1.89v1.75H100Zm0 8.11v-7.14h1.89v7.14H100Z"></path>
        <path fill={color} d="M103.39 93.23c0-.63.15-1.24.46-1.82.31-.59.75-1.04 1.31-1.35.56-.31 1.2-.46 1.9-.46 1.08 0 1.97.35 2.66 1.05.69.7 1.04 1.59 1.04 2.66s-.35 1.98-1.05 2.69c-.7.71-1.58 1.07-2.63 1.07-.65 0-1.28-.15-1.87-.44-.59-.29-1.05-.73-1.36-1.3-.31-.57-.46-1.27-.46-2.09v-.01Zm1.94.1c0 .71.17 1.25.5 1.63.33.38.75.57 1.24.57s.91-.19 1.24-.57c.33-.38.5-.92.5-1.64 0-.72-.17-1.24-.5-1.61-.33-.38-.75-.57-1.24-.57s-.91.19-1.24.57c-.34.38-.5.92-.5 1.63v-.01Z"></path>
        <path fill={color} d="M118.74 96.9h-1.89v-3.65c0-.77-.04-1.27-.12-1.5-.08-.23-.21-.4-.39-.53-.18-.12-.4-.19-.66-.19-.33 0-.62.09-.88.27-.26.18-.44.42-.53.71-.1.3-.14.84-.14 1.64v3.24h-1.89v-7.14H114v1.05c.62-.81 1.41-1.21 2.35-1.21.42 0 .8.08 1.14.23.35.15.61.34.78.58.17.24.3.5.37.79.07.3.1.72.1 1.27v4.44Z"></path>
        <path fill={color} d="M42.57 113.43v-9.86h2.98l1.79 6.73 1.77-6.73h2.99v9.86h-1.85v-7.76l-1.96 7.76h-1.92l-1.95-7.76v7.76h-1.85Z"></path>
        <path fill={color} d="M53.62 109.76c0-.63.15-1.24.46-1.82.31-.59.75-1.04 1.31-1.35.56-.31 1.2-.46 1.9-.46 1.08 0 1.97.35 2.66 1.05.69.7 1.04 1.59 1.04 2.66s-.35 1.98-1.05 2.69c-.7.71-1.58 1.07-2.63 1.07-.65 0-1.28-.15-1.87-.44-.59-.29-1.05-.73-1.36-1.3-.31-.57-.46-1.27-.46-2.09v-.01Zm1.94.1c0 .71.17 1.25.5 1.63.33.38.75.57 1.24.57s.91-.19 1.24-.57c.33-.38.5-.92.5-1.64 0-.72-.17-1.24-.5-1.61-.33-.38-.75-.57-1.24-.57s-.91.19-1.24.57c-.34.38-.5.92-.5 1.63v-.01Z"></path>
        <path fill={color} d="M69.03 113.43h-1.76v-1.05c-.29.41-.64.71-1.03.91-.4.2-.8.3-1.2.3-.82 0-1.52-.33-2.11-.99-.58-.66-.88-1.58-.88-2.77s.29-2.13.85-2.76c.57-.63 1.29-.95 2.16-.95.8 0 1.49.33 2.07 1v-3.55h1.89v9.86h.01Zm-5.05-3.73c0 .76.1 1.31.32 1.66.31.49.73.74 1.28.74.44 0 .81-.19 1.11-.55.3-.37.46-.92.46-1.66 0-.82-.15-1.41-.44-1.77-.29-.36-.67-.54-1.14-.54-.47 0-.82.18-1.13.53-.31.35-.45.89-.45 1.6l-.01-.01Z"></path>
        <path fill={color} d="M70.89 105.32v-1.75h1.89v1.75h-1.89Zm0 8.11v-7.14h1.89v7.14h-1.89Z"></path>
        <path fill={color} d="M73.89 106.29h1.05v-.54c0-.6.06-1.05.19-1.35.13-.3.36-.54.71-.72.34-.19.78-.28 1.3-.28s1.06.08 1.58.24l-.26 1.32c-.3-.07-.59-.11-.87-.11s-.47.06-.59.19-.18.37-.18.74v.5h1.41v1.49h-1.41v5.66h-1.89v-5.66h-1.05v-1.49l.01.01Z"></path>
        <path fill={color} d="M79.3 105.32v-1.75h1.89v1.75H79.3Zm0 8.11v-7.14h1.89v7.14H79.3Z"></path>
        <path fill={color} d="m87.27 111.16 1.88.32c-.24.69-.62 1.22-1.15 1.58-.53.36-1.18.54-1.96.54-1.24 0-2.16-.41-2.76-1.22-.47-.65-.71-1.47-.71-2.46 0-1.18.31-2.11.93-2.78.62-.67 1.4-1.01 2.35-1.01 1.06 0 1.9.35 2.52 1.05.61.7.91 1.78.88 3.23h-4.74c.01.56.17 1 .46 1.31.29.31.65.47 1.09.47.3 0 .54-.08.75-.24.2-.16.35-.42.46-.78v-.01Zm.11-1.91c-.01-.55-.15-.96-.42-1.25-.27-.29-.6-.43-.98-.43-.41 0-.75.15-1.02.45-.27.3-.4.71-.4 1.22h2.83l-.01.01Z"></path>
        <path fill={color} d="M92.61 113.43h-1.89v-7.14h1.76v1.02c.3-.48.57-.8.81-.95.24-.15.51-.23.82-.23.43 0 .85.12 1.24.36l-.59 1.65c-.32-.21-.61-.31-.89-.31s-.49.07-.67.22c-.18.15-.33.41-.43.79-.11.38-.16 1.18-.16 2.39v2.21-.01Z"></path>
        <path fill="#A6CA50" d="M105.08 15.74s14.1 2.63 8.34 18.17c0 0-13.01-5.59-8.62-17.41"></path>
        <path fill="#A6CA50" d="M123.33 23s3.05 9.25-8.08 10.91c0 0-.94-9.57 7.53-10.83"></path>
      </g>
      <defs fill="none">
        <clipPath id="63b1b877-f624-4b55-9be4-e49c23091775_comp-lqii61o7__item-lqfa3aa2">
          <path fill="#ffffff" d="M132.24 0v132.24H0V0h132.24z"></path>
        </clipPath>
      </defs>
    </g>
  </svg>
}